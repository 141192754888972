import React from "react";
import NavBar from "./NavBar";

export default function SecretLair() {
  return (
    <div className="Secret">
      <NavBar />
      <h2>Hello</h2>
    </div>
  );
}
